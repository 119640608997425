<template>
  <div class="manage-bookings">
    <h1>Admin Booking Manager</h1>
    <table>
      <thead>
        <tr>
          <th>Date</th>
          <th>Time</th>
          <th>User</th>
          <th>Phone</th>
          <th>Service</th>
          <th>Duration</th>
          <th>Additional Info</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        <template v-for="(group, index) in groupedBookings" :key="index">
          <tr class="date-separator">
            <td colspan="8">{{ group.date }}</td>
          </tr>
          <tr v-for="booking in group.bookings" :key="booking._id">
            <td>{{ formatDate(booking.date) }}</td>
            <td>{{ booking.time }}</td>
            <td>{{ booking.user }}</td>
            <td>{{ booking.phone }}</td>
            <td>{{ booking.service ? booking.service.name : 'No Service' }}</td>
            <td>{{ booking.duration || 'N/A' }} mins</td>
            <td>{{ booking.additionalInfo }}</td>
            <td>
              <button @click="editBooking(booking)">Edit</button>
              <button @click="confirmDeleteBooking(booking._id)">Delete</button>
            </td>
          </tr>
        </template>
      </tbody>
    </table>

    <h3>Add or Edit Booking</h3>
    <select v-model="newDate">
      <option disabled value="">Select a date</option>
      <option v-for="date in availableDates" :key="date" :value="date">
        {{ formatDate(date) }}
      </option>
    </select>
    <select v-model="newStartTime">
      <option disabled value="">Select a start time</option>
      <option v-for="slot in availableSlots" :key="slot" :value="slot">
        {{ slot }}
      </option>
    </select>
    <input v-model="newUser" placeholder="User Name" />
    <input v-model="newPhone" placeholder="Phone Number" />
    <select v-model="newService">
      <option disabled value="">Select a service</option>
      <option v-for="service in services" :key="service._id" :value="service._id">
        {{ service.name }}
      </option>
    </select>
    <input v-model="newAdditionalInfo" placeholder="Additional Information" />
    <button @click="saveBooking">{{ editing ? 'Update' : 'Add' }}</button>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      apiUrl: process.env.VUE_APP_API_BASE_URL,
      bookings: [],
      services: [],
      newDate: '',
      newStartTime: '',
      newUser: '',
      newPhone: '',
      newService: '',
      newAdditionalInfo: '',
      editing: false,
      editingBookingId: null,
      availableDates: [],
      availableSlots: [],
      serviceDurations: {}
    };
  },
  computed: {
    sortedBookings() {
      const today = new Date();
      today.setHours(0, 0, 0, 0);

      return this.bookings
        .filter(booking => {
          const bookingDate = new Date(booking.date);
          return bookingDate >= today;
        })
        .sort((a, b) => {
          const dateA = new Date(a.date).getTime();
          const dateB = new Date(b.date).getTime();

          if (dateA !== dateB) {
            return dateA - dateB;
          }

          return a.time.localeCompare(b.time);
        });
    },
    groupedBookings() {
      const grouped = [];
      let currentDate = null;
      let group = null;

      this.sortedBookings.forEach(booking => {
        const bookingDate = this.formatDate(booking.date);
        if (bookingDate !== currentDate) {
          currentDate = bookingDate;
          group = { date: currentDate, bookings: [] };
          grouped.push(group);
        }
        group.bookings.push(booking);
      });

      return grouped;
    },
  },
  methods: {
    async fetchBookings() {
      try {
        const response = await axios.get(`${this.apiUrl}/bookings`);
        this.bookings = response.data;
        this.updateAvailableSlots();
      } catch (error) {
        console.error('Failed to fetch bookings:', error);
      }
    },
    async fetchServices() {
      try {
        const response = await axios.get(`${this.apiUrl}/services`);
        this.services = response.data;
        this.serviceDurations = this.services.reduce((acc, service) => {
          acc[service._id] = service.duration;
          return acc;
        }, {});
      } catch (error) {
        console.error('Failed to fetch services:', error);
      }
    },
    generateAvailableDates() {
      const today = new Date();
      const dates = [];
      const endDate = new Date(today);
      endDate.setDate(today.getDate() + 55);

      while (today <= endDate) {
        if (today.getDay() !== 0) {
          dates.push(today.toISOString().split('T')[0]);
        }
        today.setDate(today.getDate() + 1);
      }

      this.availableDates = dates;
    },
    updateAvailableSlots() {
      const startHour = 9;
      const endHour = 17;
      const interval = 30;
      const slots = [];
      const serviceDurations = this.bookings.map(b => ({
        start: new Date(`${b.date}T${b.time}`),
        duration: this.serviceDurations[b.service] || 0
      }));

      for (let hour = startHour; hour <= endHour; hour++) {
        for (let minute = 0; minute < 60; minute += interval) {
          let slotTime = new Date();
          slotTime.setHours(hour, minute, 0, 0);

          if (!serviceDurations.some(({ start, duration }) => {
            return start <= slotTime && new Date(start.getTime() + duration * 60000) > slotTime;
          })) {
            slots.push(slotTime.toTimeString().substring(0, 5));
          }
        }
      }
      this.availableSlots = slots;
    },
    async saveBooking() {
      if (!this.newDate || !this.newStartTime || !this.newUser || !this.newPhone || !this.newService) {
        alert("Please insert all information.");
        return;
      }

      if (this.editing) {
        await this.updateBooking(this.editingBookingId);
      } else {
        await this.addBooking();
      }
      this.resetForm();
    },
    async addBooking() {
      try {
        const duration = this.serviceDurations[this.newService] || 0;
        await axios.post(`${this.apiUrl}/bookings`, {
          date: this.newDate,
          time: this.newStartTime,
          user: this.newUser,
          phone: this.newPhone,
          service: this.newService,
          additionalInfo: this.newAdditionalInfo,
          duration
        });
        this.fetchBookings();
      } catch (error) {
        console.error('Failed to add booking:', error);
      }
    },
    async updateBooking(id) {
      try {
        const duration = this.serviceDurations[this.newService] || 0;
        await axios.put(`${this.apiUrl}/bookings/${id}`, {
          date: this.newDate,
          time: this.newStartTime,
          user: this.newUser,
          phone: this.newPhone,
          service: this.newService,
          additionalInfo: this.newAdditionalInfo,
          duration
        });
        this.fetchBookings();
      } catch (error) {
        console.error('Failed to update booking:', error);
      }
    },
    async confirmDeleteBooking(id) {
      if (confirm('Are you sure you want to delete this booking?')) {
        await this.deleteBooking(id);
      }
    },
    async deleteBooking(id) {
      try {
        await axios.delete(`${this.apiUrl}/bookings/${id}`);
        this.fetchBookings();
      } catch (error) {
        console.error('Failed to delete booking:', error);
      }
    },
    editBooking(booking) {
      this.newDate = booking.date;
      this.newStartTime = booking.time;
      this.newUser = booking.user;
      this.newPhone = booking.phone;
      this.newService = booking.service;
      this.newAdditionalInfo = booking.additionalInfo;
      this.editing = true;
      this.editingBookingId = booking._id;
    },
    resetForm() {
      this.newDate = '';
      this.newStartTime = '';
      this.newUser = '';
      this.newPhone = '';
      this.newService = '';
      this.newAdditionalInfo = '';
      this.editing = false;
      this.editingBookingId = null;
    },
    formatDate(date) {
      return new Date(date).toLocaleDateString('en-GB', { weekday: 'long', day: '2-digit', month: 'long' });
    }
  },
  mounted() {
    this.fetchBookings();
    this.fetchServices();
    this.generateAvailableDates();
  }
};
</script>

<style>
h1 {
  text-align: center;
  padding: 20px;
  color: #ffffff;
}

h3 {
  padding: 20px;
  text-align: center;
  color: #ffffff;
}

table {
  width: 100%;
  border-collapse: collapse;
}

th, td {
  border: 1px solid #717171;
  padding: 8px;
  color: #D3A166;
}

th {
  background-color: #3C3B39;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
}

.manage-bookings button {
  padding: 5px;
  border: none;
  border-radius: 3px;
  background-color: #E1C186;
  color: #000000;
  cursor: pointer;
  font-weight: bolder;
  text-transform: uppercase;
}

.manage-bookings button:hover {
  background-color: #D3A166;
}

.manage-bookings input,
.manage-bookings select {
  padding: 8px;
  border: 1px solid #717171;
  border-radius: 3px;
  margin: 5px;
  color: #D3A166;
  appearance: auto; 
}

ul {
  list-style: none;
  padding: 0;
}

li {
  margin: 5px 0;
}

.manage-bookings {
  margin-bottom: 20px;
  padding: 70px;
}

.date-separator td {
  font-weight: bold;
  text-align: center;
  border: none;
  padding: 10px 0;
  color: #717171;
}
</style>